import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from "./Components/Sidebar";
import Properties from "./Pages/Properties";
import Footer from "./Components/Footer";
 // Assuming you have a Home page

const App: React.FC = () => {
    return (
        <Router>
            <div className="flex">
                <Sidebar />
                <main className="flex-1 p-4 ml-64 transition-all duration-300">
                    <Routes>
                        <Route path="/properties" element={<Properties />} />
                    </Routes>
                    <Footer />
                </main>
            </div>
        </Router>
    );
}

export default App;
