import React, { useEffect, useState, Suspense, lazy } from 'react';
import axios from 'axios';
import { Edit, Trash } from 'lucide-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Lazy load the components
const BuildingCard = lazy(() => import('./BuildingCards'));
const AddBuilding = lazy(() => import('./AddBuilding'));
const EditBuilding = lazy(() => import('./EditBuilding'));
const BlankBuildingCard = lazy(() => import('./BlankBuildingCard'));

interface Building {
    id: number;
    created_by: string;
    updated_by: string;
    created: string;
    updated: string;
    name: string;
    description: string;
    county: string;
    area: string;
    latitude: string;
    longitude: string;
}

const BuildingList: React.FC = () => {
    const [buildings, setBuildings] = useState<Building[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null);
    const [showAddBuilding, setShowAddBuilding] = useState(false);
    const [showEditBuilding, setShowEditBuilding] = useState(false);
    const [displayCards, setDisplayCards] = useState(false); // New state for card display

    useEffect(() => {
        fetchBuildings();
    }, []);

    useEffect(() => {
        // Set a 3-second delay before displaying cards
        const timer = setTimeout(() => setDisplayCards(true), 7000);
        return () => clearTimeout(timer); // Clean up timer on component unmount
    }, [buildings]);

    const fetchBuildings = async () => {
        try {
            const response = await axios.get<Building[]>('https://tenancy-bd6f061e3803.herokuapp.com/api/buildings/');
            const sortedBuildings = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setBuildings(sortedBuildings);
        } catch (error) {
            setError('Oops, we couldn\'t fetch buildings. Try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleCardClick = (building: Building) => {
        setSelectedBuilding(building);
        setShowEditBuilding(false);
    };

    const toggleAddBuilding = () => {
        setShowAddBuilding(!showAddBuilding);
        setShowEditBuilding(false);
    };

    const toggleEditBuilding = () => {
        setShowEditBuilding(!showEditBuilding);
        setShowAddBuilding(false);
    };

    const handleUpdate = async () => {
        await fetchBuildings();
        setSelectedBuilding(null); // Optionally reset the selected building
    };

    const handleDelete = async (buildingId: number) => {
        if (window.confirm('Are you sure you want to delete this building?')) {
            setLoading(true);
            try {
                const response = await axios.delete(`https://tenancy-bd6f061e3803.herokuapp.com/api/buildings`, {
                    params: { id: buildingId }
                });
                if (response.status === 200) {
                    setBuildings(buildings.filter(building => building.id !== buildingId));
                    setSelectedBuilding(null);
                    alert('Building deleted successfully');
                } else {
                    setError('Failed to delete building. Try again later.');
                }
            } catch (error) {
                console.error('Delete request failed', error);
                setError('Failed to delete building. Try again later.');
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) {
        return <div>Loading Buildings...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flex: 1, overflowY: 'auto' }}>
                <button onClick={toggleAddBuilding} className="bg-theme text-white px-4 py-2 mb-4">
                    {showAddBuilding ? 'Cancel' : 'Add Building'}
                </button>
                <Suspense fallback={<div>Loading...</div>}>
                    {showAddBuilding && <AddBuilding onAdd={handleUpdate} />}
                </Suspense>
                {buildings.length > 0 ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        {displayCards
                            ? buildings.map((building) => (
                                <BuildingCard
                                    key={building.id}
                                    building={building}
                                    onClick={() => handleCardClick(building)}
                                />
                            ))
                            : Array(5).fill(null).map((_, index) => (
                                <BlankBuildingCard key={index} />
                            ))
                        }
                    </Suspense>
                ) : (
                    <div>No buildings found.</div>
                )}
            </div>
            <div style={{ flex: 2, padding: '20px', overflowY: 'auto' }}>
                {selectedBuilding ? (
                    <div>
                        <div className="relative mx-auto w-full border-b-2">
                            <div className="bg-white p-4">
                                <div className="relative flex h-52 justify-center overflow-hidden">
                                    <div className="relative w-full">
                                        <LazyLoadImage
                                            src="https://www.brivillage.com/wp-content/uploads/2022/08/hero01.jpg"
                                            alt="Building"
                                            className="w-full h-auto"
                                            effect="blur"
                                        />
                                        <div
                                            className="absolute inset-0 bg-gradient-to-t from-black via-black to-transparent opacity-50"></div>
                                    </div>

                                    <div className="absolute bottom-0 left-5 mb-3 flex">
                                        <p className="flex items-center font-medium text-3xl text-white shadow-sm">
                                            <i className="fa fa-camera mr-2 text-xl text-white"></i>
                                            {selectedBuilding.name}
                                        </p>
                                    </div>
                                    <div className="absolute bottom-0 right-5 mb-3 flex">
                                        <p className="flex items-center font-medium text-white">
                                            <i className="mr-2 text-2xl text-white"></i>{selectedBuilding.description}
                                        </p>
                                    </div>

                                    <span
                                        className="absolute top-0 right-2 z-10 mt-3 ml-3 inline-flex select-none rounded-sm bg-[#1f93ff] px-2 py-1 text-xs font-semibold text-white">{selectedBuilding.county} | {selectedBuilding.area}</span>
                                    <span
                                        className="absolute top-0 left-0 z-10 mt-3 ml-3 inline-flex select-none rounded-lg bg-transparent px-3 py-2 text-lg font-medium text-white">
                                        <i className="fa fa-star"></i>
                                    </span>
                                </div>

                                <div className="mt-4">
                                    <p className="line-clamp-1" title={selectedBuilding.description}>{selectedBuilding.description}</p>
                                </div>
                                <div className="mt-8 grid grid-cols-2">
                                    <div className="flex items-center">
                                        <div className="relative">
                                            <div className="h-6 w-6 rounded-full bg-gray-200 md:h-8 md:w-8"></div>
                                            <span
                                                className="bg-primary-red absolute top-0 right-0 inline-block h-3 w-3 rounded-full"></span>
                                        </div>

                                        <p className="line-clamp-1 ml-2 text-gray-800">Hello</p>
                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            onClick={toggleEditBuilding}
                                            className="bg-theme text-white px-4 py-2 mb-4 flex items-center text-sm space-x-2"
                                        >
                                            <Edit className="w-5 h-5" />
                                            <span>{showEditBuilding ? 'Cancel' : 'Edit Building'}</span>
                                        </button>

                                        <button
                                            className="bg-red-500 text-white px-4 py-2 mb-4 ml-3 flex items-center text-sm space-x-2"
                                            onClick={() => handleDelete(selectedBuilding.id)}
                                        >
                                            <Trash className="w-5 h-5" />
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showEditBuilding && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <EditBuilding
                                    building={selectedBuilding}
                                    onCancel={toggleEditBuilding}
                                    onUpdate={handleUpdate}
                                />
                            </Suspense>
                        )}
                    </div>
                ) : (
                    <div>Select a building to see more details</div>
                )}
            </div>
        </div>
    );
};

export default BuildingList;
