import Buildings from "../Components/Buildings";
import { Helmet } from 'react-helmet';

const Properties: React.FC = () => {
    return (
        <div className="">
            <Helmet>
                <title>Tenancy | My Properties</title>
            </Helmet>
        <Buildings />
        </div>
    );
};

export default Properties;
