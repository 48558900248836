import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar: React.FC = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <div className="fixed top-0 left-0 h-full w-64 bg-white text-green-700" style={{
            borderRight: '0.3px solid #f0f0f0', // Example of a 3px solid border
        }}>
            <div className="p-4">
                <h1 className="text-xl font-bold">Tenancy</h1>
                <ul className="mt-4">
                    <li className={`border-b ${currentPath === '/properties' ? 'bg-green-700' : ''}`}>
                        <Link
                            to="/properties"
                            className={`block py-6 px-4 text-black ${currentPath === '/properties' ? 'text-white' : 'hover:text-white'}`}
                            style={{
                                backgroundColor: currentPath === '/properties' ? '#016617' : '#f5f5f5', // Active background color
                                transition: 'background-color 0.3s' // Smooth transition effect
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = currentPath === '/properties' ? '#016617' : '#e0e0e0'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = currentPath === '/properties' ? '#016617' : '#f5f5f5'}
                        >
                            Properties
                        </Link>
                    </li>
                    {/* Add more links here */}
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
